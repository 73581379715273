import React, { useState } from 'react'
import Stepper from '../Stepper'
import { useForm, useFormState } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Modal from '../Modal'
import CreateStudyDetails from './CreateStudyDetails'
import CreateStudyDemographics from './CreateStudyDemographics'
import CreateStudyTasks from './CreateStudyTasks'
import CreateStudyReports from './CreateStudyReports'
import CreateStudySites from './CreateStudySites'
import { FULL_DEMOGRAPHICS } from '../../utils/constants'
import {
  CREATE_STUDY_ERROR_MESSAGE,
  CREATE_STUDY_SUCCESS
} from './createStudyUtils'
import { useMutation } from '@apollo/client'
import { CREATE_STUDY_MUTATION } from '../../mutations/createStudy'
import { Button, toaster } from 'evergreen-ui'
import { createUseStyles } from 'react-jss'
import { customListStyles } from '../../styles/sharedComponentStyles'
import CreateSite from '../CreateSite'
import { useGlobalStore } from '../../hooks/useGlobalStore'
import {
  SUMMARY_ABBREVIATED_REPORT_TITLE,
  SUMMARY_REPORT_TITLE,
  KPSC_REPORT_TITLE
} from '../../utils/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import {
  updateStudyRealmCache,
  updateStudySiteCache
} from '../../services/managementService'
import { studyFormSchema } from '../StudyDetails/studyFormSchema'

const useSharedStyles = createUseStyles({
  ...customListStyles
})

const CreateStudyAdditionalOptions = ({
  openCreateSiteModal,
  currentSiteNames = []
}) => {
  const sharedClasses = useSharedStyles()
  return (
    <>
      {currentSiteNames.length > 0 && (
        <Button
          className={sharedClasses.buttonStylesTerciary}
          onClick={openCreateSiteModal}
        >
          <FontAwesomeIcon icon={faPlus} style={{ marginRight: '10px' }} />
          Create Site
        </Button>
      )}
    </>
  )
}

const CreateStudy = ({
  showModal,
  handleCloseModal,
  onStudyCreated,
  studyNames
}) => {
  const [showSiteCreation, setShowSiteCreation] = useState(false)
  const { realmId } = useGlobalStore()
  const { handleSubmit, control, watch } = useForm({
    mode: 'onChange',
    resolver: yupResolver(studyFormSchema(studyNames))
  })
  const handleCreateSiteCloseModal = () => {
    setShowSiteCreation(false)
  }
  const handleCreateSiteOpenModal = () => {
    setShowSiteCreation(true)
  }

  const { isValid } = useFormState({ control })
  const [createStudy, { loading }] = useMutation(CREATE_STUDY_MUTATION, {
    update: (cache, { data: { insert_Study } }) => {
      const newStudy = insert_Study?.returning?.[0]
      if (newStudy) {
        cache.evict({
          // re triggers study query
          id: 'ROOT_QUERY',
          fieldName: 'Study'
        })
        for (const { Site } of newStudy.Study_Sites || []) {
          updateStudySiteCache(cache, Site, newStudy)
        }
        updateStudyRealmCache(cache, { id: realmId, __typename: 'Realm' })
      }
    },
    onCompleted: result => {
      if (result?.insert_Study?.returning?.length > 0) {
        onStudyCreated(result.insert_Study.returning[0])
        toaster.success(CREATE_STUDY_SUCCESS)
        handleCloseModal()
      }
    },
    onError: _ => toaster.danger(CREATE_STUDY_ERROR_MESSAGE)
  })
  const tasks = watch('tasks')
  const hasTask = tasks && tasks.filter(t => t.value).length > 0
  const sites = watch('sites')
  const currentSiteNames = sites?.map(site => site.site.name) || []
  const hasSites = sites && sites.filter(s => s.value).length > 0
  const name = watch('name')

  const onSubmit = data => {
    data.isCollectingPHI = data.isCollectingPHI === FULL_DEMOGRAPHICS
    data.hasSummaryReport =
      !!data.reports.find(
        r => r.report.title === SUMMARY_REPORT_TITLE && r.value
      ) || false
    data.hasAbbreviatedSummaryReport =
      !!data.reports.find(
        r => r.report.title === SUMMARY_ABBREVIATED_REPORT_TITLE && r.value
      ) || false
    data.hasSummaryReportBhaKpsc =
      !!data.reports.find(
        r => r.report.title === KPSC_REPORT_TITLE && r.value
      ) || false
    data.studySites = data.sites
      .filter(s => s.value)
      .map(s => ({ site_id: s.site.id }))
    data.studyTasks = data.tasks
      .filter(t => t.value && t.task.__typename === 'Task')
      .map(t => ({ task_id: t.task.id }))
    data.studyTasksBatteries = data.tasks
      .filter(t => t.value && t.task.__typename === 'TaskBattery')
      .map(t => ({ taskBattery_id: t.task.id }))
    data.realmId = realmId
    createStudy({
      variables: {
        ...data
      }
    })
  }

  return (
    <>
      <Modal
        width={900}
        showDialog={showModal}
        handleDialogClose={handleCloseModal}
      >
        <Modal.Header
          title="Create Study"
          handleCloseModal={handleCloseModal}
        />
        <Modal.Body>
          <Stepper
            steps={['Details', 'Demographics', 'Tasks', 'Reports', 'Sites']}
            onConfirm={handleSubmit(onSubmit)}
          >
            <Stepper.Step disableNext={!isValid}>
              <CreateStudyDetails control={control} />
            </Stepper.Step>
            <Stepper.Step>
              <CreateStudyDemographics control={control} />
            </Stepper.Step>
            <Stepper.Step disableNext={!hasTask}>
              <CreateStudyTasks control={control} />
            </Stepper.Step>
            <Stepper.Step>
              <CreateStudyReports control={control} />
            </Stepper.Step>
            <Stepper.Step
              disableNext={!hasSites}
              nextLoading={loading}
              AdditionalOptions={
                <CreateStudyAdditionalOptions
                  openCreateSiteModal={handleCreateSiteOpenModal}
                  currentSiteNames={currentSiteNames}
                />
              }
            >
              <CreateStudySites
                control={control}
                handleCreateSite={handleCreateSiteOpenModal}
              />
            </Stepper.Step>
          </Stepper>
        </Modal.Body>
      </Modal>
      {showSiteCreation && (
        <CreateSite
          showModal={showSiteCreation}
          handleCloseModal={handleCreateSiteCloseModal}
          sitesNames={currentSiteNames}
          mainStudyName={name}
        />
      )}
    </>
  )
}

export default CreateStudy
