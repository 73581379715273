import React from 'react'
import { Pane } from 'evergreen-ui'
import { createUseStyles } from 'react-jss'
import { formStyles } from '../../styles/sharedComponentStyles'
import TaskList from '../TaskList'

const useSharedStyles = createUseStyles({
  ...formStyles,
  wrapper: {
    width: '100%',
    textAlign: 'left'
  },
  fields: {
    composes: '$fieldsLabel',
    width: '100%'
  }
})

const CreateStudyTasks = ({ control }) => {
  const sharedClasses = useSharedStyles()
  return (
    <Pane className={sharedClasses.wrapper} is="article">
      <Pane className={sharedClasses.fields}>
        <TaskList control={control} />
      </Pane>
    </Pane>
  )
}

export default CreateStudyTasks
