import { Pane } from 'evergreen-ui'
import React from 'react'
import { useWatch } from 'react-hook-form'
import { createUseStyles } from 'react-jss'
import { formStyles } from '../../styles/sharedComponentStyles'
import RadioField from '../forms/RadioField'
import {
  FULL_DEMOGRAPHICS,
  LIMITED_DEMOGRAPHICS
} from '../../utils/constants'
import CreateStudyOption from '../CreateStudyOption'

const useSharedStyles = createUseStyles({
  ...formStyles,
  wrapper: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    textAlign: 'left'
  },
  fields: {
    composes: '$fieldsLabel',
    width: '100%'
  },
  radios: {
    '& label': {
      display: 'flex',
      alignItems: 'flex-start',
      '& > div': {
        marginTop: '16px'
      }
    },
    '& span': {
      width: '90%'
    }
  }
})

const getDemographicsOptions = (isCollectingPHI = LIMITED_DEMOGRAPHICS) => [
  {
    label: (
      <CreateStudyOption
        title="Limited Examinee and Informant Demographics (restricted PHI)"
        description="Please do not enter any personally identifiable information (PII) or
        protected health information (PHI) into TabCAT, including but not
        limited to names, contact information, date of birth, and medical record
        number."
        visited={isCollectingPHI === LIMITED_DEMOGRAPHICS}
      />
    ),
    value: LIMITED_DEMOGRAPHICS
  },
  {
    label: (
      <CreateStudyOption
        title="Full Examinee and Informant Demographics (with PHI)"
        description="By selecting “Full Demographics (with PHI)” you are attesting that both 
        your home institution as well as the UCSF TabCAT team have approved your study’s collection of 
        PHI within the TabCAT system."
        visited={isCollectingPHI === FULL_DEMOGRAPHICS}
      />
    ),
    value: FULL_DEMOGRAPHICS
  }
]

const CreateStudyDemographics = ({ control }) => {
  const sharedClasses = useSharedStyles()
  const isCollectingPHI = useWatch({ control, name: 'isCollectingPHI' })
  const demographicsOptions = getDemographicsOptions(isCollectingPHI)
  return (
    <Pane className={sharedClasses.wrapper} is="article">
      <Pane className={sharedClasses.fields}>
        <RadioField
          name="isCollectingPHI"
          label="Assign one of the demographics settings to your study."
          control={control}
          size={16}
          defaultValue={LIMITED_DEMOGRAPHICS}
          options={demographicsOptions}
          className={sharedClasses.radios}
        />
      </Pane>
    </Pane>
  )
}

export default CreateStudyDemographics
