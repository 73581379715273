import gql from 'graphql-tag'

export const CREATE_STUDY_MUTATION = gql`
  mutation CreateStudy(
    $name: String!
    $aims: String
    $design: String
    $notes: String
    $realmId: Int!
    $leadInvestigator: String
    $studyTasks: [Study_Task_insert_input!]!
    $studyTasksBatteries: [Study_TaskBattery_insert_input!]!
    $studySites: [Study_Site_insert_input!]!
    $hasSummaryReport: Boolean
    $hasAbbreviatedSummaryReport: Boolean
    $hasSummaryReportBhaKpsc: Boolean
    $isCollectingPHI: Boolean
  ) {
    insert_Study(
      objects: {
        name: $name
        aims: $aims
        leadInvestigator: $leadInvestigator
        design: $design
        notes: $notes
        Study_Tasks: { data: $studyTasks }
        Study_TaskBatteries: { data: $studyTasksBatteries }
        Study_Sites: { data: $studySites }
        realm_id: $realmId
        isCollectingPHI: $isCollectingPHI
        hasSummaryReport: $hasSummaryReport
        hasSummaryReportBhaKpsc: $hasSummaryReportBhaKpsc
        hasAbbreviatedSummaryReport: $hasAbbreviatedSummaryReport
      }
    ) {
      returning {
        id
        infoDescription
        isBlocked
        isCollectingPHI
        createdAt
        name
        aims
        design
        leadInvestigator
        notes
        Study_Sites_aggregate {
          aggregate {
            count
          }
        }
        Study_Sites {
          Site {
            id
            Site_Users {
              user_id
            }
          }
        }
        Study_Tasks_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
`
