import React, { useRef } from 'react'
import { login, isAuthenticated } from '../utils/auth'
import { Pane, Table, Button, TextInputField } from 'evergreen-ui'
import { createUseStyles } from 'react-jss'
import CreateStudy from '../components/CreateStudy'
import {
  navStyles,
  customListStyles,
  tableSectionStyles,
  generalStyles
} from '../styles/sharedComponentStyles'
import StudyDetails from '../components/StudyDetails'
import { useGlobalStore } from '../hooks/useGlobalStore'
import { SITES_AND_USERS, TASKS } from '../utils/constants'
import LoadingSpinner from '../components/LoadingSpinner'
import { useRoleAccess } from '../hooks/useRoleAccess'
import _ from 'lodash'
import { useHandleStudiesList } from '../hooks/useHandleStudiesList'
import { useLocation } from '@reach/router'
import { getRealmIdFromURL } from '../utils/filters'

const useSharedStyles = createUseStyles({
  ...generalStyles,
  ...navStyles,
  ...customListStyles,
  ...tableSectionStyles,
  elementSelector: {
    composes: '$tableCell',
    cursor: 'pointer'
  },
  tableHeaderTitle: {
    textTransform: 'none'
  }
})

const StudiesPage = () => {
  const { realmId } = useGlobalStore()
  const { canEditStudy, canOpenUserDetails } = useRoleAccess()
  const studiesSectionRef = useRef(null)
  const location = useLocation()

  const routeRealm = location ? Number(getRealmIdFromURL(location)) : -1

  // Realm Id that is set depending on the realm from URL and the global store
  const realRealmId =
    routeRealm === false ? -1 : routeRealm ? routeRealm : realmId

  const sharedClasses = useSharedStyles()
  const {
    loading,
    filterValue,
    initialTabIndex,
    studiesSet,
    selectedStudy,
    showCreateStudyDialog,
    showStudyDetail,
    handleStudyFilter,
    handleCreateStudyCloseModal,
    handleCreateStudyOpenModal,
    handleOpenSideSheet,
    countUsers,
    onStudyCreation,
    handleStudyDetailsClose
  } = useHandleStudiesList({ studiesSectionRef })

  if (!isAuthenticated()) {
    login()
    return (
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <h2>Redirecting to login...</h2>
      </Pane>
    )
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      <nav className={sharedClasses.nav}>
        <Pane className={sharedClasses.secondaryNav} flexWrap="wrap">
          <Pane flex={1}>
            <h1>Studies</h1>
          </Pane>

          <Pane
            className={sharedClasses.autoComplete}
            display="flex"
            marginRight="20px"
          >
            <TextInputField
              value={filterValue}
              label="Search for a study name"
              onChange={e => handleStudyFilter(e.target.value)}
              style={{
                padding: '20px'
              }}
            />
          </Pane>
          {canEditStudy && realRealmId > 0 && (
            <Button
              className={sharedClasses.buttonStyles}
              onClick={handleCreateStudyOpenModal}
            >
              Create New Study
            </Button>
          )}
        </Pane>
      </nav>
      {!loading && studiesSet.length <= 0 && (
        <Pane
          display="flex"
          padding={30}
          justifyContent="center"
          alignItems="center"
        >
          <h2>Sorry, there are no studies for this realm.</h2>
        </Pane>
      )}
      <Pane ref={studiesSectionRef} className={sharedClasses.section}>
        {studiesSet.map(study => {
          if (!study.dummyObj) {
            return (
              <Pane
                key={study.id}
                data-testid={`study-${_.kebabCase(study.name)}`}
              >
                <Table className={sharedClasses.table}>
                  <Table.Head className={sharedClasses.tableHeader}>
                    <Table.TextHeaderCell data-testid="study-name">
                      <span className={sharedClasses.tableHeaderTitle}>
                        {study.name}
                      </span>
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell textAlign="right">
                      <Button
                        className={sharedClasses.buttonStyles}
                        marginRight={0}
                        onClick={() => handleOpenSideSheet(study.id)}
                      >
                        View Study
                      </Button>
                    </Table.TextHeaderCell>
                  </Table.Head>
                  <Table.Body
                    height={100}
                    backgroundColor="#ffffff"
                    borderRadius="6px"
                  >
                    <Table.Row borderColor="#ffffff">
                      <Table.TextCell
                        className={sharedClasses.elementSelector}
                        onClick={() =>
                          handleOpenSideSheet(study.id, SITES_AND_USERS)
                        }
                        data-testid={`${study.id}-sites-count`}
                      >
                        {study.Study_Sites_aggregate && (
                          <>
                            {study.Study_Sites_aggregate.aggregate.count} Sites
                          </>
                        )}
                      </Table.TextCell>
                      <Table.TextCell
                        className={sharedClasses.elementSelector}
                        onClick={() =>
                          handleOpenSideSheet(study.id, SITES_AND_USERS)
                        }
                        data-testid={`${study.id}-users-count`}
                      >
                        {study.Study_Sites && (
                          <>{countUsers(study.Study_Sites)} Users</>
                        )}
                      </Table.TextCell>
                      <Table.TextCell
                        className={sharedClasses.elementSelector}
                        onClick={() => handleOpenSideSheet(study.id, TASKS)}
                        data-testid={`${study.id}-tasks-count`}
                      >
                        {study.Study_Tasks_aggregate?.aggregate?.count +
                          study.Study_TaskBatteries_aggregate?.aggregate
                            ?.count}{' '}
                        Tasks
                      </Table.TextCell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Pane>
            )
          }
        })}
      </Pane>
      {showStudyDetail && (
        <StudyDetails
          studyId={selectedStudy.id}
          show={showStudyDetail}
          handleDetailsClose={handleStudyDetailsClose}
          parentSectionRef={studiesSectionRef}
          initialTabIndex={initialTabIndex}
          studyNames={studiesSet.map(s => s.name)}
          canEdit={canEditStudy}
          canOpenUserDetails={canOpenUserDetails}
        />
      )}
      {showCreateStudyDialog && (
        <CreateStudy
          showModal={showCreateStudyDialog}
          handleCloseModal={handleCreateStudyCloseModal}
          studyNames={studiesSet.map(s => s.name)}
          onStudyCreated={onStudyCreation}
        />
      )}
    </>
  )
}

export default StudiesPage
