import { Pane } from 'evergreen-ui'
import React from 'react'
import { createUseStyles } from 'react-jss'
import {
  customListStyles,
  formStyles
} from '../../styles/sharedComponentStyles'
import { breakpoints } from '../../styles/theme'
import InputTextField from '../forms/InputTextField'
import TextAreaField from '../forms/TextAreaField'
const useSharedStyles = createUseStyles({
  ...formStyles,
  ...customListStyles,
  wrapper: {
    display: 'grid',
    width: '100%',
    height: '100%',
    backgroundColor: '#ffffff',
    borderRadius: '6px',
    overflowY: 'auto',
    '& label': {
      textAlign: 'left',
      marginLeft: '12px'
    }
  },
  form: {
    width: '100%'
  },
  fieldsWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    [`@media (max-width: ${breakpoints.sm})`]: {
      gridTemplateColumns: 'auto'
    }
  },
  fields: {
    display: 'grid',
    flexBasis: '50%',
    gridTemplateRows: '50px 120px',
    rowGap: '40px',
    padding: '20px'
  },
  widerField: {
    gridColumn: '1 / 3',
    padding: '20px',
    '& textarea': {
      width: '97%'
    }
  }
})

const CreateStudyDetails = ({ control }) => {
  const sharedClasses = useSharedStyles()
  return (
    <Pane className={sharedClasses.wrapper} is="article">
      <Pane className={sharedClasses.fieldsWrapper}>
        <Pane className={sharedClasses.fields}>
          <InputTextField
            control={control}
            name="name"
            defaultValue=""
            label="Name"
            showErrorMessage
            required
          />
          <TextAreaField
            control={control}
            name="aims"
            placeholder=""
            label="Aims"
            inputHeight={100}
            defaultValue=""
          />
        </Pane>
        <Pane className={sharedClasses.fields}>
          <InputTextField
            control={control}
            name="leadInvestigator"
            defaultValue=""
            label="Lead Investigator"
          />
          <TextAreaField
            control={control}
            name="notes"
            placeholder="Enter notes"
            label="Notes"
            inputHeight={100}
            defaultValue=""
          />
        </Pane>
        <Pane className={sharedClasses.widerField}>
          <TextAreaField
            control={control}
            name="design"
            label="Design"
            inputHeight={100}
            defaultValue=""
          />
        </Pane>
      </Pane>
    </Pane>
  )
}

export default CreateStudyDetails
